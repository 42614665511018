import styled from "styled-components";
import ArrowDesktop from '../../../assets/icons/active-arrow-desktop.svg';
import ArrowDesktopBlue from '../../../assets/icons/active-arrow-desktop-blue.png';

export const ApplicationFormWrap = styled.div`
  padding: 0;
  padding: 24px;
  background-color: rgba(240, 240, 240, 0.5);
  border-radius: 8px;
  margin-bottom: 45px;
  @media ${({ theme }) => theme.media.medium} {
    margin-bottom: 0;
  }
`;

export const ApplicationForm = styled.form`
  margin-top: ${({ theme }) => theme.grid.gridGutterMedium};
  margin-bottom: 24px;
  @media ${({ theme }) => theme.media.extraSmall} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media ${({ theme }) => theme.media.medium} {
    display: block
  }
`;

export const ApplicationFormText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 8px 0 0 0;
`;

export const PointingArrowWrapper = styled.div`
  @media ${({ theme }) => theme.media.extraSmall} {
    width: 100%;
    position: relative;
    &:after{
      content: '';
      width: 78px;
      height: 86px;
      position: absolute;
      right: 0px;
      top: 50%;
      background-image: url(${!window.location.href.includes('blue') ? ArrowDesktop : ArrowDesktopBlue});
      transform: translate(calc(100% - 8px), calc(-100% + 10px));
    }
  }
`;