import * as s from './user-cabinet.styled';

import { Container } from "components/container/container.styled";
import Title from 'components/title/title';
import Description from 'components/description/description';
import Subtitle from 'components/subtitle';
import Card from 'components/card/card';
import Button from 'components/button';

import { useForm } from "react-hook-form";

import FormControl from "components/form-control";
import Input from 'components/input';
import Select from 'components/select';
import FormError from 'components/form-error';
import { useEffect, useState } from 'react';
import Loader from 'components/loader';

import paypal from 'assets/images/paypal.png';
import creditCard from 'assets/images/card-icon.png';
import cards from 'assets/images/cards.png';
import info from 'assets/images/info-icon.svg';

import axios from "axios";
import { useNavigate } from 'react-router';

type FormData = {
  cardNumber: string;
  cvv: string;
  expireDate: string;
  year: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  zip: string;
  vehicleMileage: string;
  vehicleBrandName: string;
  vehicleModelName: string;
  errorMessages: object;
};

type VerhicleData = {
  vehicleYear: string;
  vehicleMileage: string;
  brandName: string;
  modelName: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
};

type CardData = {
  cardNumber: string;
};

const UserCabinet: React.FC = () => {
  const [cardData, setCardData] = useState<any>({});
  const [userData, setUserData] = useState<VerhicleData>({
    vehicleYear: '0000',
    vehicleMileage: '0000',
    brandName: 'xxxxx',
    modelName: 'xxxxx',
    // company: 'xxxxxx',
    fullName: 'xxxxx',
    phoneNumber: 'xxxx',
    email: 'xxxx',
    zipCode: 'xxxx'
  });
  const [guestData, setGuestData]: any = useState(null);
  const [monthlyPlan, setMonthlyPlan]: any = useState(null);
  const [personalDataEditStatus, setPersonalDataEditStatus] = useState(false);
  const [cardDataEditStatus, setCardDataEditStatus] = useState(false);
  const [isConfirmPopupActive, setIsConfirmPopupActive] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const [cardNumberValidation, setCardNumberValidation] = useState(true);
  const [expireDateValidation, setExpireDateValidation] = useState(true);
  const [cvvValidation, setCvvValidation] = useState(true);
  const [monthValidation, setMonthValidation] = useState(true);
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('userData') as any)) {
      getPersonalData();
      getCardData();
    } else {
      navigate('/login');
    }
  }, []);


  const { register, watch, setValue, getValues, handleSubmit, formState: { errors } } = useForm<FormData>();
  const { register: cardRegister, watch: cardWatch, setValue: cardSetValue, getValues: cardGetValues, control, handleSubmit: cardHandleSubmit, formState: { errors: cardErrors } } = useForm<FormData>();
  const watchShowAge = cardWatch("cardNumber");
  const watchCvv = cardWatch("cvv");
  const wExpireDate = cardWatch("expireDate");
  const wPhone = watch("phone");
  const wZip = watch("zip");

  const onSubmit = (data: any) => {
    if (data.phone.includes('_')) {
      setPhoneValidation(false);
      return;
    } else {
      setPhoneValidation(true);
    }

    const reqData = {
      "id": (userData as any)?.id,
      "vehicleYear": data.year,
      "vehicleMileage": data.vehicleMileage,
      "firstName": data.first_name,
      "lastName": data.last_name,
      "company": data.vehicleBrand,
      // "email": data.email,
      "phone": data.phone,
      "zipCode": data.zip
    }

    setLoadingStatus(true);
    axios.put('https://haspro-dev.azurewebsites.net/api/customer', reqData, {
      headers: {
        'Authorization': `Bearer ${(JSON.parse(localStorage.getItem('userData') as any).accessToken.token)}`
      }
    })
      .then(function (response) {
        setLoadingStatus(false);
        getPersonalData();
        setPersonalDataEditStatus(false);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        if (error.response.status === 401) {
          navigate("/login");
        }
      });
  }

  const cardOnSubmit = (data: any) => {
    if (data.cardNumber.includes('_')) {
      setCardNumberValidation(false);
      return;
    } else {
      setCardNumberValidation(true);
    }

    if (data.cvv.includes('_')) {
      setCvvValidation(false);
      return;
    } else {
      setCvvValidation(true);
    }

    if (data.expireDate.includes('_')) {
      setExpireDateValidation(false);
      return;
    } else {
      setExpireDateValidation(true);
    }

    if (+data?.expireDate?.split('/')[0] > 12) {
      setMonthValidation(false);
      return;
    } else {
      setMonthValidation(true);
    }

    const reqData = {
      "customerId": (userData as any)?.id,
      "cardNumber": data.cardNumber,
      "cardCvv": data.cvv,
      "cardExpiryMonth": data?.expireDate?.split('/')[0],
      "cardExpiryYear": data?.expireDate?.split('/')[1]
    }
    setLoadingStatus(true);
    axios.post('https://haspro-dev.azurewebsites.net/api/CustomerCard', reqData, {
      headers: {
        'Authorization': `Bearer ${(JSON.parse(localStorage.getItem('userData') as any).accessToken.token)}`
      }
    })
      .then(function (response) {
        setLoadingStatus(false);
        getCardData();
        setCardDataEditStatus(false);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        if (error.response?.data.message.includes('card number')) {
          setErrorMessage('Invalid card number.');
        } else if (error.response?.data.message.includes('Expired')) {
          setErrorMessage('Expired Card.');
        } else {
          setErrorMessage(error.response?.data.message);
        }
        if (error.response.status === 401) {
          navigate("/login");
        }
      });
  }

  const getPersonalData = () => {
    setLoadingStatus(true);
    axios.get('https://haspro-dev.azurewebsites.net/api/customer/GetCurrentCustomer', {
      headers: {
        'Authorization': `Bearer ${(JSON.parse(localStorage.getItem('userData') as any).accessToken.token)}`
      }
    })
      .then(function (response) {
        setLoadingStatus(false);
        setValue('first_name', response.data.data.firstName);
        setValue('last_name', response.data.data.lastName);
        setValue('phone', response.data.data.phoneNumber);
        setValue('email', response.data.data.email);
        setValue('zip', response.data.data.zipCode.toString());

        setValue('vehicleMileage', response.data.data.vehicleMileage);
        setValue('vehicleBrandName', response.data.data.brandName);
        setValue('vehicleModelName', response.data.data.modelName);
        setValue('year', response.data.data.vehicleYear);
        setUserData(response.data.data);


      })
      .catch(function (error) {
        setLoadingStatus(false);
        if (error?.response?.status === 401) {
          navigate("/login");
        }
      });

  }

  const getCardData = () => {
    setLoadingStatus(true);
    axios.post('https://haspro-dev.azurewebsites.net/api/SubscriptionChargeBee/GetCurrentSubscription', {}, {
      headers: {
        'Authorization': `Bearer ${(JSON.parse(localStorage.getItem('userData') as any).accessToken.token)}`
      }
    })
      .then(function (response) {
        setLoadingStatus(false);
        if (response?.data?.data) {
          setCardData(response.data.data);
          // cardSetValue('cardNumber', response.data.data.card?.iin.toString() + '000000' + response.data.data.card?.last4.toString());
          // cardSetValue('expireDate', (response.data.data.card?.expiryMonth.toString() + '/' + response.data.data.card?.expiryYear.toString().slice(2, 4)).toString());
        }
      })
      .catch(function (error) {
        setLoadingStatus(false);
        if (error?.response?.status === 401) {
          navigate("/login");
        }
      });
  }

  const cancelPlan = () => {
    setLoadingStatus(true);
    axios.post('https://haspro-dev.azurewebsites.net/api/SubscriptionChargeBee/CancelSubscription', {}, {
      headers: {
        'Authorization': `Bearer ${(JSON.parse(localStorage.getItem('userData') as any).accessToken.token)}`
      }
    })
      .then(function (response) {
        setLoadingStatus(false);
        getCardData();
        setIsConfirmPopupActive(false);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        if (error.response.status === 401) {
          navigate("/login");
        }
      });
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => console.log(value, name, type));
    return () => subscription.unsubscribe();
  }, [watch])

  useEffect(() => {
    if (!phoneValidation) {
      const d = (getValues().phone as any);
      if (d.includes('_')) {
        setPhoneValidation(false);
      } else {
        setPhoneValidation(true);
      }
    }
  }, [wPhone]);

  useEffect(() => {
    const subscription = cardWatch((value, { name, type }) => console.log(value, name, type));
    return () => subscription.unsubscribe();
  }, [cardWatch])

  useEffect(() => {
    if (!cardNumberValidation) {
      const d = (cardGetValues().cardNumber as any);
      if (d.includes('_')) {
        setCardNumberValidation(false);
      } else {
        setCardNumberValidation(true);
      }
    }
  }, [watchShowAge]);

  useEffect(() => {
    if (!cvvValidation) {
      const d = (cardGetValues().cvv as any);
      if (d.includes('_')) {
        setCvvValidation(false);
      } else {
        setCvvValidation(true);
      }
    }
  }, [watchCvv]);

  useEffect(() => {
    if (!cardNumberValidation) {
      const d = (cardGetValues().expireDate as any);

      if (d.includes('_')) {
        setExpireDateValidation(false);
      } else {
        setExpireDateValidation(true);
      }
    }

    if (!monthValidation && cardNumberValidation) {
      const d = (cardGetValues().cardNumber as any);
      if (+d?.split('/')[0] > 12) {
        setMonthValidation(false);
        // return;
      } else {
        setMonthValidation(true);
      }
    } else {
      setMonthValidation(true);
    }
  }, [wExpireDate]);


  return (
    <>
      {loadingStatus ? <Loader /> : ''}
      <s.Checkout>
        <Container width="533px">

          <s.CenteredTitle>User Cabinet</s.CenteredTitle>
          <s.End>
            <s.Logout onClick={() => navigate("/login")}>Logout</s.Logout>
          </s.End>

          <Card>
            <s.EditLine>
              <s.CheckoutSubtitle>Personal Information</s.CheckoutSubtitle>
              <span onClick={() => setPersonalDataEditStatus(!personalDataEditStatus)}>
                {!personalDataEditStatus ? 'Edit' : 'Cancel'}
              </span>
            </s.EditLine>

            <s.InformationList style={{ display: !personalDataEditStatus ? 'block' : 'none' }}>
              <s.InformationListItem>
                <s.Text color="light">Full name:</s.Text>
                <s.Text>{userData?.fullName}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Phone number:</s.Text>
                <s.Text>{userData?.phoneNumber}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Email:</s.Text>
                <s.Text>{userData?.email}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Zip code:</s.Text>
                <s.Text>{userData?.zipCode}</s.Text>
              </s.InformationListItem>
            </s.InformationList>

            <s.CheckoutForm style={{ display: personalDataEditStatus ? 'block' : 'none' }} onSubmit={handleSubmit(onSubmit)}>
              <s.InputGroup>
                <FormControl>
                  <Input label="First Name" name={'first_name'} register={register} type="text" validate={{
                    required: 'First Name is required',
                    pattern: {
                      value: /^[a-zA-Z ]{2,30}$/, // /^(?=.*[A-Z])/gm
                      message: 'Invalid first name'
                    }
                  }} />
                  {errors.first_name && (<FormError>{errors.first_name.message}</FormError>)}
                </FormControl>
                <FormControl>
                  <Input label="Last Name" name={'last_name'} register={register} type="text" validate={{
                    required: 'Last Name is required',
                    pattern: {
                      value: /^[a-zA-Z ]{2,30}$/, // /^(?=.*[A-Z])/gm
                      message: 'Invalid last name'
                    }
                  }} />
                  {errors.last_name && (<FormError>{errors.last_name.message}</FormError>)}
                </FormControl>
                <FormControl>
                  <Input label="Phone number" name={'phone'} mask="phone" register={register} type="tel" required={true} validate={{ required: 'Phone number is required' }} />
                  {!phoneValidation && (<FormError>Enter valid phone</FormError>)}
                  {errors.phone && (<FormError>{errors.phone.message}</FormError>)}
                </FormControl>
                <FormControl>
                  <Input disabled label="Email" name={'email'} register={register} type="email" validate={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address"
                    }
                  }} />
                  {errors.email && (<FormError>{errors.email.message}</FormError>)}
                </FormControl>
                <FormControl size="large">
                  <Input label="Zip code" mask="zip" name={'zip'} register={register} type="text" />
                </FormControl>
              </s.InputGroup>
              <Button variant="primary" type="submit">Continue</Button>
            </s.CheckoutForm>

          </Card>


          <Card>
            <s.EditLine>
              <s.CheckoutSubtitle>Credit Card Info</s.CheckoutSubtitle>
              <span onClick={() => setCardDataEditStatus(!cardDataEditStatus)}>
                {!cardDataEditStatus ? 'Edit' : 'Cancel'}
              </span>
            </s.EditLine>

            <s.InformationList style={{ display: !cardDataEditStatus ? 'block' : 'none' }}>
              <s.InformationListItem>
                <s.Text color="light">Card Number:</s.Text>
                <s.Text>{cardData?.card?.iin?.toString() + '******' + cardData?.card?.last4?.toString()}</s.Text>
              </s.InformationListItem>
              <s.InformationListItem>
                <s.Text color="light">Expire Date:</s.Text>
                <s.Text>{(cardData?.card?.expiryMonth?.toString() + '/' + cardData?.card?.expiryYear?.toString().slice(2, 4)).toString()}</s.Text>
              </s.InformationListItem>
            </s.InformationList>

            <s.CheckoutForm onSubmit={cardHandleSubmit(cardOnSubmit)} style={{ display: cardDataEditStatus ? 'block' : 'none' }}>
              <s.CardNumberLabel>Card Number
                <img src={info} />
              </s.CardNumberLabel>
              <FormControl>
                <s.IconInputWrapper>
                  <Input label="0000-0000-0000-0000" mask="cardNumber" name="cardNumber" register={cardRegister} type="text" min="0" validate={{ required: 'Card Number is required' }} />
                  <s.InputIcon>
                    <img src={cards} width="91" />
                  </s.InputIcon>
                </s.IconInputWrapper>
                {!cardNumberValidation && (<FormError>Min. length is 16</FormError>)}
                {cardErrors.cardNumber && (<FormError>{cardErrors.cardNumber.message}</FormError>)}
              </FormControl>

              <s.ThreeInputGroup>
                <FormControl>
                  <s.IconInputWrapper>
                    <Input label="CVV" name={'cvv'} mask={'cvv'} register={cardRegister} type="text" validate={{ required: 'CVV is required' }} />
                    <s.InputIcon>
                      <img src={info} />
                    </s.InputIcon>
                  </s.IconInputWrapper>
                  {!cvvValidation && (<FormError>Enter valid CVV</FormError>)}
                  {cardErrors.cvv && (<FormError>{cardErrors.cvv.message}</FormError>)}
                </FormControl>

                <FormControl>
                  <Input label="MM/YY" mask="mmyy" name={'expireDate'} register={cardRegister} type="text" validate={{ required: 'Expire Date is required' }} />
                  {!expireDateValidation && (<FormError>Enter valid date</FormError>)}
                  {(!monthValidation && expireDateValidation) && (<FormError>Month should not be more than 12</FormError>)}
                  {cardErrors.expireDate && (<FormError>{cardErrors.expireDate.message}</FormError>)}
                </FormControl>

              </s.ThreeInputGroup>
              <Button variant="primary" type="submit">Continue</Button>
              <FormError>{errorMessage} </FormError>
            </s.CheckoutForm>
          </Card>

          <Card>
            <s.CheckoutSubtitle>Vehicle</s.CheckoutSubtitle>
            <s.InformationList>
              <s.InformationListItem>
                <s.Text color="light">Vehicle:</s.Text>
                <s.Text>{userData?.vehicleYear + (userData?.brandName ? (', ' + userData?.brandName + ' ' + userData?.modelName) : '')}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Mileage:</s.Text>
                <s.Text>{userData?.vehicleMileage + ' miles'}</s.Text>
              </s.InformationListItem>
            </s.InformationList>
          </Card>

          {(cardData as any)?.subscription_items ?
            <Card>
              <s.CheckoutSubtitle>Your Subscription</s.CheckoutSubtitle>
              <s.PlanWrapper>
                <div>
                  <s.Text>Haspro Plan</s.Text> <br />
                  {/* <s.Text color="light">Free trial ends 2/20/22</s.Text> */}
                </div>
                <div>
                  <s.Text>
                    {(cardData as any)?.subscription_items ?
                      <strong>${(cardData as any)?.subscription_items[0]?.amount / 100}/mo</strong>
                      : ''
                    }
                  </s.Text>
                  <br />
                  <s.Cancel onClick={() => setIsConfirmPopupActive(true)}>Cancel Plan</s.Cancel>
                </div>
              </s.PlanWrapper>
              {/* <Button variant="primary" type="submit">Continue</Button> */}
            </Card>
            :
            <Card>
              <s.CheckoutRedSubtitle>You do not have subscription</s.CheckoutRedSubtitle>
            </Card>
          }
        </Container>
        <s.Separator></s.Separator>


        {isConfirmPopupActive ?
          <s.PopupWrapper>
            <Card>
              <h1>Are You Sure?</h1>
              <s.BtnGroup>
                <Button variant="primary" onClick={() => setIsConfirmPopupActive(false)}>No</Button>
                <Button variant="warning" onClick={() => cancelPlan()}>Yes</Button>
              </s.BtnGroup>
            </Card>
          </s.PopupWrapper>
          : ''}

      </s.Checkout>
    </>
  )
};


export default UserCabinet;

const currenctYear = new Date().getFullYear();
const years = Array(currenctYear - 1989).fill('').map((v, idx) => {
  const year = (currenctYear - idx).toString();
  return { 'value': year, label: year }
});


const errorMessages = {
  rerquired: "Field is required",
  email: "Please enter valid email adress",
}