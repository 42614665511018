import styled from "styled-components";

export const Testimonials = styled.section`
  padding: ${({theme}) => theme.grid.gridGutterLarge} 0 ${({theme}) => theme.grid.gridGutterLarge};
  background-color: ${({theme}) => theme.colors.bg};
  @media ${({theme}) => theme.media.extraSmall} {
    padding-top: 35px;
    padding-bottom: 35px;
   }
   @media ${({theme}) => theme.media.medium} {
    padding: ${({theme}) => theme.grid.gridGutterLarge} 0 ${({theme}) => theme.grid.gridGutterLarge};
    background-color: ${({theme}) => theme.colors.bg};
   }
`;

export const Testimonial = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    margin-right: 12px;
    height: 64px;
    width: 64px;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
`;

export const Title = styled.h2`
position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({theme}) => theme.colors.primary};
  margin-top: 0;
  margin-bottom: 38px;
  text-align: center;
  &::before {
    position: absolute;
    content: "“";
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    color: ${({theme}) => theme.colors.lightBlue};
    z-index: -1;
    top: -23px;
    left: -7px;
  }
`;

export const PreTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 50px;
  margin-top: 0;
  text-align: center;
`;

