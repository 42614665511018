import styled from "styled-components";

export const Subtitle = styled.h2<{alignment?: string}>`
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${({theme}) => theme.colors?.primary};
    margin-top: 0;
    text-align: ${(props)=>props.alignment ? props.alignment : ''};
`;