import styled from "styled-components";

export const Application = styled.section`
  padding-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  padding-bottom: ${({ theme }) => theme.grid?.gridGutter};
`;

export const ApplicationInner = styled.div`
  @media ${({ theme }) => theme.media?.extraSmall} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  @media ${({ theme }) => theme.media?.medium} {
    display: grid;
    grid-template-columns: calc(50%  - 16px)  calc(50%  - 16px);
    grid-gap: ${({ theme }) => theme.grid?.gridGutterMedium};
  }
  @media ${({ theme }) => theme.media?.large} {
    grid-template-columns: 386px auto;
    grid-gap: 78px;
  }
`;

export const ApplicationText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 18px;
  @media ${({ theme }) => theme.media?.medium} {
    text-align: center;
  }
  @media ${({ theme }) => theme.media?.large} { 
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ApplicationContent = styled.div`
  h1 {
    margin-bottom: 19px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    h1 {
      text-align: center;
    }
  }
`;
export const ApplicationLabelWrap = styled.div`
  max-width: 173px;
  margin: 0 auto;
  @media ${({ theme }) => theme.media?.large} {  
    margin: 0;
  }
`;
export const ApplicationLabelDescription = styled.p`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14.1029px;
  line-height: 18px;
  margin: 8px 0 0 0;
`;

export const ApplicationLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px;
  width: 173px;
  height: 173px;
  border-radius: 50%;
  text-align: center;
  margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  background-color: ${({ theme }) => theme.colors?.primary};
  color: ${({ theme }) => theme.colors?.white};
  @media ${({ theme }) => theme.media?.large} {  
    margin: 0;
  }
`;

export const ApplicationLabelFirstPrice = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 36.3086px;
  line-height: 43px;
`;

export const ApplicationLabelFirstText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14.9506px;
  line-height: 19px;
  margin: 0;
`;

export const ApplicationLabelLine = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;


export const ApplicationLabelPrice = styled.div<{ old?: boolean }>`
position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 25.6296px;
  line-height: 34px;
  &::before {
    ${(props) => props.old ? 'content: "";' : ''}
    position: absolute;
    display: block;
    width: 110%;
    height: 1px;
    background-color: #8A8A8A;
    transform: rotate(-40deg);
    top: 50%;
  }
  &::after {
    ${(props) => props.old ? 'content: "";' : ''}
    position: absolute;
    display: block;
    width: 110%;
    height: 1px;
    background-color: #8A8A8A;
    transform: rotate(40deg);
    top: 50%;
  }
`;

export const ApplicationLabelInfo = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10.679px;
  line-height: 13px;
  margin: 4px 0 0 0;
  text-transform: uppercase;
`;

export const ApplicationRow = styled.div`
  @media ${({ theme }) => theme.media?.medium} {  
    margin-top: 36px;

  }
  @media ${({ theme }) => theme.media?.large} { 
    justify-content: space-around; 
    display: flex;
    align-items: center;   
  }
`;

export const ApplicationRating = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
`;

export const ApplicationRatingText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18.2266px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors?.darkGrey};
  margin: 0;
  @media ${({ theme }) => theme.media?.extraSmall} { 
    padding-bottom: 34px
  }
  @media ${({ theme }) => theme.media?.medium} { 
    padding-bottom: 0px
  }
`;

export const ApplicationList = styled.ul`
  padding: ${({theme}) => theme.grid?.gridGutter};
  border: 1px solid ${({ theme }) => theme.colors?.grey};
  border-radius: 8px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  margin-top: ${({theme}) => theme.grid?.gridGutter};
  li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    svg {
      margin-right: 3px;
    }
  }
  @media ${({ theme }) => theme.media?.extraSmall} {   
    display: none
  }
  @media ${({ theme }) => theme.media?.medium} {   
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: ${({theme}) => theme.grid?.gridGutterMedium};
  }
  
`;