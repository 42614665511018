import * as s from './container.styled';

interface TContainer {
    width?: string;
}

const Container: React.FC<TContainer> = ({ width, ...props }) => (
    <s.Container {...props} width={width}>
    </s.Container>
);

export default Container;
