import styled from "styled-components";


export const Benefits = styled.section`
  padding: ${({ theme }) => theme.grid?.gridGutterLarge} 0 ${({ theme }) => theme.grid?.gridGutterMedium};
  background-color: ${({ theme }) => theme.colors?.bg};
  margin-bottom: ${({ theme }) => theme.grid?.gridGutterLarge};
  overflow: hidden;
  @media ${({ theme }) => theme.media?.extraSmall} { 
    margin-bottom: 0px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    margin-bottom: ${({ theme }) => theme.grid?.gridGutterLarge};
  }
`;

export const BenefitsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  margin: 0 -${({ theme }) => theme.grid?.gridGutter};
  @media ${({ theme }) => theme.media?.extraSmall} { 
    padding-left: 8px;
    padding-right: 8px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    padding-left: 0px;
    padding-right: 0px;
  }
  @media ${({ theme }) => theme.media?.extraLarge} { 
    margin: 0 -${({ theme }) => theme.grid?.gridGutterLarge};
  }
`;

export const BenefitsListItem = styled.li`
  width: 50%;
  padding: 0 ${({ theme }) => theme.grid?.gridGutter};
  margin-bottom: ${({ theme }) => theme.grid?.gridGutterMedium};
  @media ${({ theme }) => theme.media?.extraSmall} { 
      padding: 0 8px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    width: 33.33%;
    padding: 0 ${({ theme }) => theme.grid?.gridGutter};
  }
  @media ${({ theme }) => theme.media?.extraLarge} {
    padding: 0 40px;
  }
  img {
    max-height: 64px;
    margin: 0 auto;
  }
  h3 {
    border-top: 2px solid ${({ theme }) => theme.colors?.grey};  
    padding-top: ${({ theme }) => theme.grid?.gridGutter};
    margin: ${({ theme }) => theme.grid?.gridGutterSmall} 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  p {
    margin: ${({ theme }) => theme.grid?.gridGutterSmall} 0 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;