import styled from "styled-components";

export const Repair = styled.section`
  margin-bottom: ${({ theme }) => theme.grid?.gridGutterLarge};
  margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  @media ${({ theme }) => theme.media?.extraSmall} {
    margin-top: 0px;
    margin-bottom: 0px;
   }
  @media ${({ theme }) => theme.media?.medium} {
    margin-bottom: ${({ theme }) => theme.grid?.gridGutterLarge};
    margin-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  }
`;

export const RepairGrid = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors?.grey};
  padding-top: ${({ theme }) => theme.grid?.gridGutterMedium};
  @media ${({ theme }) => theme.media?.extraSmall} {
   border-top: none;
   padding-top: 0px;
  }
  @media ${({ theme }) => theme.media?.medium} {
    border-top: 2px solid ${({ theme }) => theme.colors?.grey};
    padding-top: ${({ theme }) => theme.grid?.gridGutterMedium};
    display: grid;
    grid-template-columns: calc(50%  - 16px)  calc(50%  - 16px);
    grid-gap: ${({ theme }) => theme.grid?.gridGutterMedium};
  }
  @media ${({ theme }) => theme.media?.large} {
    grid-template-columns: 386px auto;
  }
`;

export const RepairList = styled.ul`
  width: 100%;
  margin-bottom: 90px;
  @media ${({ theme }) => theme.media?.medium} {
    max-width: 386px;
    margin-bottom: 0;
  }
`;

export const RepairListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.grid?.gridGutter};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RepairListTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 8px 0;
  color: ${({ theme }) => theme.colors?.primary}
`;

export const RepairListDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`;

export const RepairContent = styled.div`
  
`;

export const RepairCosts = styled.div`
  position: relative;
`;

export const RepairIcon = styled.div`
  margin-top: ${({ theme }) => theme.grid?.gridGutterLarge};
  padding: 13.5%;
  svg {
    display: block;
    
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 
  }
  @media ${({ theme }) => theme.media?.extraSmall} { 
    margin-top: 38px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    margin-top: ${({ theme }) => theme.grid?.gridGutterLarge};
  }
`;