import * as s from './application.styled';
import Title from 'components/title/title';
import Container from 'components/container/container';
import ApplicationForm from '../application-form';
import Icon from 'components/icons';

const Application: React.FC = () => (
  <s.Application>
    <Container>
      <s.ApplicationInner>
        <ApplicationForm></ApplicationForm>
        <s.ApplicationContent>
          <Title>Haspro Warranty Offers <br /> A Plan For Everyone</Title>
          <s.ApplicationText>*Special Limited Time Intro Offer</s.ApplicationText>
          <s.ApplicationRow>
            <s.ApplicationLabelWrap>
              <s.ApplicationLabel>
                <s.ApplicationLabelFirstPrice>$0</s.ApplicationLabelFirstPrice>
                <s.ApplicationLabelFirstText>1st Month</s.ApplicationLabelFirstText>
                <s.ApplicationLabelLine>
                  <s.ApplicationLabelPrice old={true}>$25</s.ApplicationLabelPrice>
                  <s.ApplicationLabelPrice>$9<sup>99</sup></s.ApplicationLabelPrice>
                </s.ApplicationLabelLine>
                <s.ApplicationLabelInfo>
                  per month starting 30 days after enrollment
                </s.ApplicationLabelInfo>
              </s.ApplicationLabel>
              <s.ApplicationLabelDescription>Cancel Anytime!</s.ApplicationLabelDescription>
            </s.ApplicationLabelWrap>
            <s.ApplicationRating>
              <Icon.Stars />
              <s.ApplicationRatingText>5 star rating</s.ApplicationRatingText>
            </s.ApplicationRating>
          </s.ApplicationRow>
          <s.ApplicationList>
            {data.map(({title}, index) => <li key={index}><Icon.Check/>{title}</li>)}
          </s.ApplicationList>
        </s.ApplicationContent>
      </s.ApplicationInner>
    </Container>
  </s.Application>
)
export default Application;


const data = [
  {
    "title": "Tires",
  },
  {
    "title": "Electrical",
  },
  {
    "title": "Glass",
  },
  {
    "title": "Trip Interruption",
  },
  {
    "title": "Towing",
  },
  {
    "title": "Fuel Delivery",
  },
  {
    "title": "Car Rental",
  },
  {
    "title": "Lockout services",
  },
  {
    "title": "Brake system",
  },
  {
    "title": "Flat tire replacement"
  }
]