import { Container } from "components/container/container.styled";
import { Description } from "components/description/description.styled";
import { Title } from "components/title/title.styled";
import { Subtitle } from "components/subtitle/subtitle.styled";
import styled from "styled-components";

export const Checkout = styled.section`
  padding-top: 64px;
  @media ${({ theme }) => theme.media?.extraSmall} { 
    padding-top: 34px;
  }
  @media ${({ theme }) => theme.media?.large} { 
    padding-top: 64px;
  }
`

export const TCenter = styled.div`
  text-align: center;
`

export const CenteredTitle = styled(Title)`
  text-align: center;
  margin-bottom: 16px;
`

export const CenteredDescription = styled(Description)`
  text-align: center;
  margin-bottom: 28px;
  font-weight: 600;
  @media ${({ theme }) => theme.media?.extraSmall} { 
    font-weight: 400;
  }
  @media ${({ theme }) => theme.media?.large} { 
    font-weight: 600;
  }
`

export const CheckoutSubtitle = styled(Subtitle)`
  @media ${({ theme }) => theme.media?.extraSmall} { 
    font-size: 24px;
    line-height: 32px;
    margin-top:8px;
    margin-bottom:8px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    font-size: 24px;
    line-height: 32px;
    margin-top:8px;
    margin-bottom:8px;
  }
`

export const TotalDueAmount = styled.h2`
  font-weight: 600;
  margin: 0px;
  color: ${({ theme }) => theme.colors?.grey1};
  @media ${({ theme }) => theme.media?.extraSmall} { 
    font-size: 20px;
    line-height: 24px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    font-size: 24px;
    line-height: 32px;
  }
`;

export const InformationList = styled.ul`

`;

export const InformationListItem = styled.li`
  &:not(:last-child){
    margin-bottom: 8px;
  }
  span{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    &:nth-child(1){
      color: #969696;
    }
    &:nth-child(2){
      color: #575757;
      margin-left: 8px;
    }
  }
`;

export const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.color === 'light' ? '#969696' : '#575757'};
`;

export const LightText = styled(Text)`
  font-weight: 400;
  margin-top: 8px;
`;

export const ChangePlan = styled.p`
  margin-top: 18px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors?.primary};
`;

export const PlanWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h2{
    margin: 0px
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors?.primary};
  text-decoration: underline;
`;

export const CheckoutForm = styled.form`
  margin-top: 68px;
  button{
    max-width: 100%;
  }
  @media ${({ theme }) => theme.media?.extraSmall} { 
    margin-top: 42px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    margin-top: 68px;
  }
`

export const ThreeInputGroup = styled.div`
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr 1fr 1fr;
`

export const PaymentTypeWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
`

export const PaymentType = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors?.white};
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 16px;
  margin-bottom: 18px;
  cursor: pointer;
  &.active{
    border-color: ${({ theme }) => theme.colors?.primary};
  }
  &:nth-child(1){
    img{
      width: 27px;
    }
  }
  &:nth-child(2){
    img{
      width: 62px;
    }
  }
  span{
    width: 100%;
    margin-top: 8px;
    text-align: center;
  }
`

export const CardNumberLabel = styled.label`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #969696;
  margin-bottom: 8px;
  img{
    margin-left: 8px;
  }
`

export const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
`

export const Separator = styled(Container)`
  border-bottom: 2px solid #E2E2E2;
  padding-bottom: 56px;
  @media ${({ theme }) => theme.media?.extraSmall} { 
   margin-bottom: 23px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    margin-bottom: 0px;
  }
`

export const IconInputWrapper = styled.div`
  position: relative;
`