import React, { useState } from 'react';

import {
  Routes,
  Route
} from "react-router-dom";

import GlobalStyles from 'styles/global';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';

import Header from 'components/header';
import Footer from 'components/footer';
import Landing from 'pages/landing';
import Checkout from 'pages/checkout';
import CheckoutSuccess from 'pages/checkoutSuccess';
import Login from 'pages/auth/login';
import SetEmail from 'pages/auth/setEmail';
import ResetPassword from 'pages/auth/resetPassword';
import UserCabinet from 'pages/user-cabinet';

import { useEffect } from 'react';
import { useNavigate } from 'react-router';

function App() {
  const [fbPixel, setFbPixel] = useState<any>(null);
  const location = useNavigate();

  useEffect(() => {
    import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.init('220657296006131') // facebookPixelId
      ReactPixel.pageView();

      setFbPixel(ReactPixel);
    })
  },[])

  useEffect(() => {
    if(fbPixel){
      if(window.location.pathname==="/checkout"){
        fbPixel.track('Lead');
      } else if(window.location.pathname==="/checkout-success"){
        fbPixel.track('Purchase');
      }
    }
  }, [location, fbPixel])


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header></Header>
      <main>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/blue" element={<Landing />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout-success" element={<CheckoutSuccess />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password-step1" element={<SetEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/user-cabinet" element={ <UserCabinet /> } />
        </Routes>
      </main>
      <Footer></Footer>
    </ThemeProvider>
  );
}

export default App;