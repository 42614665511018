import * as s from './repair-cost.styled';

interface TRepairCost {
  children: React.ReactNode;
  left :number;
  top: number;
  position?: string;
}
const RepairCost: React.FC<TRepairCost> = ({children, top, left, position}) => (
  <s.RepairCost top={top} left={left}>{children}<s.Triangle position={position} /></s.RepairCost>
);

export default RepairCost;