import styled from "styled-components";

export const CallToAction = styled.section`
  margin: 28px 0;
  text-align: center;
  @media ${({theme}) => theme.media.extraSmall} {
   margin-top: 40px;
   margin-bottom: 52px;
  }
  @media ${({theme}) => theme.media.medium} {
    margin: 28px 0;
  }
`;