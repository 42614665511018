import styled from "styled-components";

export const Container = styled.div<{ width?: string }>`
  max-width: ${(props) => props.width ? props.width : ({theme}) => theme.grid?.gridMaxWidth};
  padding: 0 ${({theme}) => theme.grid?.gridGutter};
  margin: 0 auto;
  @media ${({ theme }) => theme.media?.extraSmall} {
    padding: 0 ${({theme}) => theme.grid?.gridGutterMedium};
  }
  @media ${({ theme }) => theme.media?.medium} {
     padding: 0 ${({theme}) => theme.grid?.gridGutter};
  }
`;