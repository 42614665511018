import * as s from './header.styled';
import logo from 'assets/images/logo.png';
import Container from 'components/container';
import { NavLink } from 'react-router-dom';

const Header: React.FC = () => (


    <s.HeaderWrap>
        <Container>
            <s.Header>
                {window.location.href.includes('user-cabinet') ?

                    <a href='https://hasprostage.wpengine.com/' target="_blank" rel="noopener noreferrer">
                        <img src={logo} alt="HasPro" />
                    </a>
                    :
                    <NavLink to='/'>
                        <img src={logo} alt="HasPro" />
                    </NavLink>
                }
            </s.Header>
        </Container>
    </s.HeaderWrap>
);

export default Header;