import * as s from './title.styled';

interface TTitle {
  children: React.ReactNode;
}

const Title: React.FC<TTitle> = ({children}) => (
  <s.Title>{children}</s.Title>
)

export default Title;