import styled from "styled-components";

export const Footer = styled.footer`
  padding: 23px 0;
  text-align: center;
  button {
    margin-bottom: 65px;
  }
  p {
    margin: 0 0 30px;
  }
  @media ${({theme})=> theme.media.extraSmall} { 
    padding-top: 0px;
  }
  @media ${({theme})=> theme.media.medium} { 
    padding: 23px 0;
  }
`;

export const Copyright = styled.p`
  margin: 0 0 30px;
  @media ${({theme})=> theme.media.extraSmall} { 
    margin-bottom: 19px!important;
  }
  @media ${({theme})=> theme.media.medium} { 
    margin: 0 0 30px!important;
  }
`;

export const Logo = styled.div`
  margin: 0 auto 19px;
  max-width: 90px;
  img {
    width: 100%;
    display: block;
  }
`;

export const Brands = styled.div`
  margin: 0 auto 19px;
  max-width: 168px;
  img {
    width: 100%;
    display: block;
  }
`;