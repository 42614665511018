import * as s from './repair.styled';
import Container from 'components/container';
import Subtitle from 'components/subtitle';
import Icon from 'components/icons';
import RepairCost from '../repair-cost';

const Repair: React.FC = () => (
  <s.Repair>
    <Container>
      <s.RepairGrid>
      <s.RepairList>
        {
          data.map(({title, description}, index)=> 
            <s.RepairListItem key={index}>
              <s.RepairListTitle>{title}</s.RepairListTitle>
              <s.RepairListDescription>{description}</s.RepairListDescription>
            </s.RepairListItem>
          )
        }
      </s.RepairList>
      <s.RepairContent>
        <Subtitle alignment='center'>Cost of repairs without Haspro Warranty:</Subtitle>
        <s.RepairCosts>
          <s.RepairIcon>
            <Icon.Car/>
          </s.RepairIcon>
          {
            repair.map(({title, price, left, top, position}, index) => 
                <RepairCost left={left} top={top} key={index} position={position}>{price}</RepairCost>
            )
          }
        </s.RepairCosts>
      </s.RepairContent>
      </s.RepairGrid>
    </Container>
  </s.Repair>
);

export default Repair;

const data = [
  {
    "title": "Did you know?",
    "description": "50% of car buyers purchase a warranty at the dealership. Most of the other 50% don’t buy since it’s out of their budget.",
  },
  {
    "title": "How is it only $9.99/month you might ask?",
    "description": "50% of car buyers purchase a warranty at the dealership. Most of the other 50% don’t buy since it’s out of their budget.",
  },
  {
    "title": "Who is this plan for?",
    "description": "50% of car buyers purchase a warranty at the dealership. Most of the other 50% don’t buy since it’s out of their budget.",
  },
  {
    "title": "Can I get a plan that covers more?",
    "description": "50% of car buyers purchase a warranty at the dealership. Most of the other 50% don’t buy since it’s out of their budget.",
  },
]

const repair = [
  {
    "title": "Towing",
    "price": "$150",
    top: 51.5,
    left: 0,
    position: 'bottom-right',
  },
  {
    "title": "Electrical",
    "price": "$550",
    top: 29,
    left: 2,
    position: 'bottom-right',
  },
  {
    "title": "Glass",
    "price": "$500",
    top: 6,
    left: 52,
    position: 'bottom-left',
  },
  {
    "title": "Brake System",
    "price": "$750",
    top: 47,
    left: 84,
    position: 'bottom-left',
  },
  {
    "title": "Car Rental",
    "price": "$275",
    top: 82,
    left: 80,
    position: 'top-left',
  },
  {
    "title": "Trip Interruption",
    "price": "$350",
    top: 82,
    left: 52,
    position: 'top-left',
  },
  {
    "title": "Flat Tire Replacement",
    "price": "$250",
    top: 82,
    left: 6,
    position: 'top-right',
  },
]