import styled from "styled-components";

export const RepairCost = styled.div<{top: number, left: number}>`
  position: absolute;
  padding: 5px 7px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  @media ${({theme}) => theme.media.large} {
    font-size: 20px;
    line-height: 24px;
    padding: 8px 14px;
  }
`;

export const Triangle = styled.div<{position?: string}>`
  position: absolute;
  height: 2px;
  width: 10px;
  ${props => props.position === 'bottom-right' || props.position === 'top-right' ? 'right: -6px;left: auto;': ''}
  ${props => props.position === 'bottom-left'  || props.position === 'top-left'? 'left: -6px;right: auto;': ''}
  ${props => props.position === 'top-right' || props.position === 'top-left' ? 'top: 1px;bottom: auto;': ''}
  ${props => props.position === 'bottom-left' || props.position === 'bottom-right' ? 'bottom: 1px;top: auto;': ''}
  bottom: 1px;
  background-color: ${({theme}) => theme.colors.primary};
  border-radius: 50px;
  box-shadow: 0 0 0 1px ${({theme}) => theme.colors.primary};
  &::before {
    display: block;
    content: '';
    height: 2px;
    width: 10px;
    background-color: ${({theme}) => theme.colors.primary};
    border-radius: 50px;
    box-shadow: 0 0 0 1px ${({theme}) => theme.colors.primary};
    ${props => props.position === 'bottom-left' ? 'transform: translateX(-1px) translateY(-3px) rotate(-35deg);': ''}
    ${props => props.position === 'bottom-right' ? 'transform: translateX(1px) translateY(-3px) rotate(35deg);': ''}
    ${props => props.position === 'top-right' ? 'transform: translateX(1px) translateY(3px) rotate(-35deg);': ''}
    ${props => props.position === 'top-left' ? 'transform: translateX(1px) translateY(3px) rotate(35deg);': ''}
  }
  @media ${({theme}) => theme.media.large} { 
    width: 20px;
    box-shadow: 0 0 0 3px ${({theme}) => theme.colors.primary};
    ${props => props.position === 'bottom-right' || props.position === 'top-right' ? 'right: -10px;left: auto;': ''}
    ${props => props.position === 'bottom-left'  || props.position === 'top-left'? 'left: -10px;right: auto;': ''}
    ${props => props.position === 'top-right' || props.position === 'top-left' ? 'top: 3px;bottom: auto;': ''}
    ${props => props.position === 'bottom-left' || props.position === 'bottom-right' ? 'bottom: 3px;top: auto;': ''}
    &::before {
      width: 20px;
      ${props => props.position === 'bottom-left' ? 'transform: translateX(-3px) translateY(-6px) rotate(-40deg);': ''}
      ${props => props.position === 'bottom-right' ? 'transform: translateX(3px) translateY(-6px) rotate(40deg);': ''}
      ${props => props.position === 'top-right' ? 'transform: translateX(3px) translateY(6px) rotate(-40deg);': ''}
      ${props => props.position === 'top-left' ? 'transform: translateX(-3px) translateY(6px) rotate(40deg);': ''}

      // ${props => props.position === 'bottom-right' ? 'transform: translateX(-3px) translateY(-6px) rotate(-40deg);': ''}
      // ${props => props.position === 'bottom-left' ? 'transform: translateX(3px) translateY(-6px) rotate(40deg);': ''}
      box-shadow: 0 0 0 3px ${({theme}) => theme.colors.primary};
    }
  }
`;