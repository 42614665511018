import React from 'react';
import * as s from './card.styled';

interface TCard  {
    // radius?: 'primary' | 'secondary' | 'link' | 'warning';
    // full?: '100'
}

const Card: React.FC<TCard> = ({ children }) => (
    <s.Card>
        {children} 
    </s.Card>
);

export default Card;