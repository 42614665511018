import styled from "styled-components";

export const Title = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${({theme}) => theme.colors?.primary};
    margin-top: 0;
    @media  ${({theme}) => theme.media?.large} {
        font-size: 48px;
        line-height: 56px;
    }
`;