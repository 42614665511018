
import Repair from './repair';
import CallToAction from './call-to-action';
import Testimonial from './testimonial';
import Benefits from './benefits';
import Application from './application';

const Landing: React.FC = () => {

  const scrollToActivationForm = () => {
    document.getElementById('activationForm')?.scrollIntoView({ behavior: 'smooth' });

  }

  return (
    <>
      <Application />
      <Repair />
      <CallToAction onClick={() => scrollToActivationForm()} />
      <Testimonial />
      <CallToAction onClick={() => scrollToActivationForm()} />
      <Benefits />
      <CallToAction onClick={() => scrollToActivationForm()} />
    </>
  )
};


export default Landing;