import { useForm } from "react-hook-form";

import * as s from './application-form.styled';
import FormControl from "components/form-control";
import Subtitle from 'components/subtitle';
import Button from 'components/button';
import Input from 'components/input';
import Select from 'components/select';
import FormError from 'components/form-error';
import Loader from 'components/loader';

import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

type FormData = {
  year: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  zip: string;
  mileage: string;
  vehicleMake: string;
  vehicleModel: string;
  errorMessages: object;
};


const ApplicationForm: React.FC = () => {
  // const [email, setEmail] = useState('');
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);

  const [phoneValidation, setPhoneValidation] = useState(true);
  const navigate = useNavigate();

  const { register, setValue, getValues, watch, control, handleSubmit, formState: { errors } } = useForm<FormData>();

  useEffect(() => {
    setLoadingStatus(true);
    axios.get(`https://haspro-dev.azurewebsites.net/api/vehicle/GetMakes`)
      .then(function (response) {
        setLoadingStatus(false);
        const options = response.data.map(function (row: any) {
          return { value: row.id, label: row.name }
        })
        setVehicleMakes(options);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        // setErrorMessage(error.response?.data.message);
      });
  }, []);

  const subscription = watch('vehicleMake');
  useEffect(() => {
    if ((subscription as any)?.value) {
      getVehicleModels((subscription as any).value);
    }
    // return () => subscription.unsubscribe();
  }, [subscription])

  const watchPhone = watch('phone');
  useEffect(() => {
    if (!phoneValidation) {
      const d = (getValues().phone as any);
      if (d.includes('_')) {
        setPhoneValidation(false);
      } else {
        setPhoneValidation(true);
      }
    }
  }, [watchPhone]);

  const getVehicleModels = (id: any) => {
    setVehicleModels([]);
    setValue('vehicleModel', '')
    axios.get(`https://haspro-dev.azurewebsites.net/api/vehicle/GetModels?makeid=${id}`)
      .then(function (response) {
        // setLoadingStatus(false);
        const options = response.data.map(function (row: any) {
          return { value: row.id, label: row.name }
        })
        setVehicleModels(options);
      })
      .catch(function (error) {
        // setLoadingStatus(false);
        // setErrorMessage(error.response?.data.message);
      });
  }

  const onSubmit = (data: any) => {
    if (data.phone.includes('_')) {
      setPhoneValidation(false);
      return;
    } else {
      setPhoneValidation(true);
    }

    const reqData = {
      "vehicleYear": data.year.value,
      "vehicleMileage": data.vehicleYear,
      "brandId": data.vehicleMake.value,
      "modelId": data.vehicleModel.value,
      "brandName": data.vehicleMake.label,
      "modelName": data.vehicleModel.label,
      "firstName": data.first_name,
      "lastName": data.last_name,
      "company": data.vehicleBrand,
      "email": data.email,
      "phone": data.phone,
      "zipCode": data.zip
    }

    setLoadingStatus(true);
    axios.get(`https://haspro-dev.azurewebsites.net/api/customer/CheckExistingCustomer?customerEmail=${reqData.email}`)
      .then(function (response) {
        setLoadingStatus(false);
        localStorage.setItem('guestData', JSON.stringify(reqData));
        navigate("/checkout");
      })
      .catch(function (error) {
        setLoadingStatus(false);
        setErrorMessage(error.response?.data.message);
      });
  }

  return (
    <>
      {loadingStatus ? <Loader /> : ''}

      <s.ApplicationFormWrap id="activationForm">
        <Subtitle>To activate your plan fill out the information below</Subtitle>
        <s.ApplicationForm onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <Select options={years} name="year" control={control} label="Vehicle year" validate={{ required: 'Year is required' }} />
            {errors.year && (<FormError>{errors.year.message}</FormError>)}
          </FormControl>
          <FormControl>
            <Select options={vehicleMakes} control={control} label="Vehicle make" name="vehicleMake" validate={
              { required: 'Vehicle Make is required' }
            } />
            {errors.vehicleMake && (<FormError>{errors.vehicleMake.message}</FormError>)}
          </FormControl>
          {vehicleModels?.length > 0 ?
            <FormControl>
              <Select options={vehicleModels} control={control} label="Vehicle model" name="vehicleModel" validate={{ required: 'Vehicle Model is required' }} />
              {errors.vehicleModel && (<FormError>{errors.vehicleModel.message}</FormError>)}
            </FormControl>
            : ''
          }
          <FormControl>
            <Input label="Vehicle Mileage" mask="millage" name="vehicleYear" register={register} type="number" min="0" />
          </FormControl>
          <FormControl>
            <Input label="First Name" name={'first_name'} register={register} type="text" validate={{
              required: 'First Name is required',
              pattern: {
                value: /^[a-zA-Z ]{2,30}$/, // /^(?=.*[A-Z])/gm
                message: 'Invalid first name'
              }
            }} />
            {errors.first_name && (<FormError>{errors.first_name.message}</FormError>)}
          </FormControl>
          <FormControl>
            <Input label="Last Name" name={'last_name'} register={register} type="text" validate={{
              required: 'Last Name is required',
              pattern: {
                value: /^[a-zA-Z ]{2,30}$/, // /^(?=.*[A-Z])/gm
                message: 'Invalid last name'
              }
              }} />
            {errors.last_name && (<FormError>{errors.last_name.message}</FormError>)}
          </FormControl>
          <FormControl>
            <Input label="Phone number" name={'phone'} mask="phone" register={register} type="tel" required={true} validate={{ required: 'Phone number is required' }} />
            {!phoneValidation && (<FormError>Enter valid phone</FormError>)}
            {errors.phone && (<FormError>{errors.phone.message}</FormError>)}
          </FormControl>
          <FormControl>
            <Input label="Email" name={'email'} register={register} type="email" validate={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }
            }} />
            {errors.email && (<FormError>{errors.email.message}</FormError>)}
          </FormControl>
          <FormControl size="large">
            <Input label="Zip code" mask="zip" name={'zip'} register={register} type="text" />
          </FormControl>
          <s.PointingArrowWrapper>
            <Button full='100' variant='primary' type="submit">Active your Plan</Button>
          </s.PointingArrowWrapper>
          <FormError>{errorMessage} </FormError>
        </s.ApplicationForm>

        <s.ApplicationFormText>*By submitting this form, I am providing my authorization for Haspro Warranty to contact me by phone, text, or email with the information provided above.</s.ApplicationFormText>

      </s.ApplicationFormWrap>
    </>
  )
};
export default ApplicationForm;

const currenctYear = (new Date().getFullYear() + 1);
const years = Array(currenctYear - 1989).fill('').map((v, idx) => {
  const year = (currenctYear - idx).toString();
  return { 'value': year, label: year }
});


const errorMessages = {
  rerquired: "Field is required",
  email: "Please enter valid email adress",
}