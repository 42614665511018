import * as s from './subtitle.styled';

interface TSubtitle {
  children: React.ReactNode;
  alignment?: string;
}

const Subtitle: React.FC<TSubtitle> = ({children, alignment}) => (
  <s.Subtitle alignment={alignment}>{children}</s.Subtitle>
)

export default Subtitle;