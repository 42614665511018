import * as s from './setEmail.styled';

import { Container } from "components/container/container.styled";
import Button from 'components/button';

import { useForm } from "react-hook-form";

import Input from 'components/input';
import FormError from 'components/form-error';
import { useEffect, useState } from 'react';

import axios from "axios";
import Loader from 'components/loader';

import EmailIcon from 'assets/icons/email.png';

type FormData = {
  email: string;
  password: string;
};

const SetEmail: React.FC = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, [])

  const { register, setValue, control, handleSubmit, reset, formState: { errors } } = useForm<FormData>();
  const onSubmit = (data: any) => {
    setLoadingStatus(true);
    axios.post('https://haspro-dev.azurewebsites.net/api/auth/forgot', data)
      .then(function (response) {
        setLoadingStatus(false);
        reset();
      })
      .catch(function (error) {
        setLoadingStatus(false);
      });
  }

  return (
    <>
      {loadingStatus ? <Loader /> : ''}
      <s.Checkout>
        <Container width="512px">
          <s.AuthFormCard>
            <s.AuthFormTitle>Enter your email</s.AuthFormTitle>
            <s.CheckoutForm onSubmit={handleSubmit(onSubmit)}>
              <s.IconInputWrapper>

                <s.AuthFormFormControl>
                  <Input label="Email" name={'email'} register={register} type="email" validate={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address"
                    }
                  }} />
                  <s.InputIcon>
                    <img src={EmailIcon} width="91" />
                  </s.InputIcon>
                </s.AuthFormFormControl>
                {errors.email && (<FormError>{errors.email.message}</FormError>)}
              </s.IconInputWrapper>

              <Button variant="primary" type="submit">Send</Button>
            </s.CheckoutForm>
          </s.AuthFormCard>
        </Container>
      </s.Checkout>
      <s.Separator></s.Separator>
    </>
  )
};


export default SetEmail;