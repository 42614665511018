import styled from 'styled-components';

export const Button = styled.button<{ variant?: 'primary' | 'secondary' | 'link' | 'warning', full?: '100' }>`
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 23px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 14px 32px;
    max-width: 338px;
    background-color: ${(props) => props.variant === 'primary' ? ({theme}) => theme.colors?.primary : props.variant === 'secondary' ? 'transparent' : ''};
    color: ${(props) => props.variant === 'primary' ? ({theme}) => theme.colors?.white : props.variant === 'secondary' ? 'var(--blue)' : '#000'};
    transition: ${({theme}) => theme.durations?.ms300};

    &:disabled {
        background: #7FACDE;
        cursor: not-allowed;
    };

    &:hover {
      background-color: rgba(${(props) => props.variant === 'primary' ? ({theme}) => theme.colors?.primary : props.variant === 'secondary' ? 'transparent' : ''}, .8);
    }

    @media ${({theme}) => theme.media?.extraSmall} {
      max-width: ${(props)=> props.full === '100'?'100%':'338px'};
    }
    @media ${({theme}) => theme.media?.medium} {
      max-width: 338px;
    }
`;