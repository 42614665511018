import styled from "styled-components";

export const Description = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 18px;
    @media ${({ theme }) => theme.media?.medium} {
    text-align: center;
    }
    @media ${({ theme }) => theme.media?.large} { 
    font-size: 24px;
    line-height: 32px;
    }
`;