import * as s from './form-control.styled';

interface TFormControl {
  size?: 'large';
}

const FormControl: React.FC<TFormControl> = ({children, size}) => (
  <s.FormControl size={size}>{children}</s.FormControl>
);

export default FormControl;