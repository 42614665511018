import * as s from './call-to-action.styled';
import Button from 'components/button';
import Container from 'components/container';

interface TCallToAction {
    [otherProps: string]: any;
}

const CallToAction: React.FC<TCallToAction> = ({ ...props }) => (
    <s.CallToAction {...props}>
        <Container>
          <Button variant='primary'>Active your Plan</Button>
        </Container>
    </s.CallToAction>
);

export default CallToAction; 