import * as s from './checkout.styled';

import { Container } from "components/container/container.styled";
import Title from 'components/title/title';
import Description from 'components/description/description';
import Subtitle from 'components/subtitle';
import Card from 'components/card/card';
import Button from 'components/button';

import { useForm } from "react-hook-form";

import FormControl from "components/form-control";
import Input from 'components/input';
import Select from 'components/select';
import FormError from 'components/form-error';
import { useEffect, useState } from 'react';

import paypal from 'assets/images/paypal.png';
import creditCard from 'assets/images/card-icon.png';
import cards from 'assets/images/cards.png';
import info from 'assets/images/info-icon.svg';

import axios from "axios";
import { useNavigate } from 'react-router';
import Loader from 'components/loader';

type FormData = {
  cardNumber: number;
  cvv: string;
  expireDate: string;
  zipCode: string;
};

const Checkout: React.FC = () => {
  const [activePaymentType, setActivePaymentType] = useState(0);
  const [guestData, setGuestData]: any = useState(null);
  const [monthlyPlan, setMonthlyPlan]: any = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [cardNumberValidation, setCardNumberValidation] = useState(true);
  const [expireDateValidation, setExpireDateValidation] = useState(true);
  const [cvvValidation, setCvvValidation] = useState(true);
  const [monthValidation, setMonthValidation] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const localUserData = JSON.parse((localStorage.getItem('guestData') as string));
    (localUserData as any).vehicleMileage = parseInt(localUserData?.vehicleMileage);
    setGuestData(localUserData);

    setValue('zipCode', JSON.parse((localStorage.getItem('guestData') as string) as any)?.zipCode || '99999');

    setLoadingStatus(true);
    axios.get('https://haspro-dev.azurewebsites.net/api/Item?itemId=haspro_plan')
      .then(function (response) {
        setLoadingStatus(false);
        setMonthlyPlan(response.data.data?.itemPrice.find((item: any) => item.id === 'Haspro_A'));
      })
      .catch(function (error) {
        setLoadingStatus(false);
      });
  }, []);


  const { register, setValue, getValues, watch, control, handleSubmit, formState: { errors } } = useForm<FormData>();

  useEffect(() => {
    if (!cardNumberValidation) {
      const d = (getValues().cardNumber as any);
      if (d.includes('_')) {
        setCardNumberValidation(false);
      } else {
        setCardNumberValidation(true);
      }
    }
  }, [watch('cardNumber')]);

  useEffect(() => {
    if (!cvvValidation) {
      const d = (getValues().cvv as any);
      if (d.includes('_')) {
        setCvvValidation(false);
      } else {
        setCvvValidation(true);
      }
    }
  }, [watch('cvv')]);

  useEffect(() => {
    if (!cardNumberValidation) {
      const d = (getValues().expireDate as any);

      if (d.includes('_')) {
        setExpireDateValidation(false);
      } else {
        setExpireDateValidation(true);
      }

    }

    if (!monthValidation && cardNumberValidation) {
      const d = (getValues().expireDate as any);
      if (+d?.split('/')[0] > 12) {
        setMonthValidation(false);
        // return;
      } else {
        setMonthValidation(true);
      }
    } else {
      setMonthValidation(true);
    }
  }, [watch('expireDate')]);


  const onSubmit = (data: any) => {
    if (data.cardNumber.includes('_')) {
      setCardNumberValidation(false);
      return;
    } else {
      setCardNumberValidation(true);
    }

    if (data.cvv.includes('_')) {
      setCvvValidation(false);
      return;
    } else {
      setCvvValidation(true);
    }

    if (data.expireDate.includes('_')) {
      setExpireDateValidation(false);
      return;
    } else {
      setExpireDateValidation(true);
    }
    
    if (+data?.expireDate?.split('/')[0] > 12) {
      setMonthValidation(false);
      return;
    } else {
      setMonthValidation(true);
    }

    setLoadingStatus(true);
    axios.post('https://haspro-dev.azurewebsites.net/api/Customer', guestData)
      .then(function (response) {
        const reqData = {
          "firstName": guestData?.firstName,
          "lastName": guestData?.lastName,
          "brandId": guestData?.brandId,
          "modelId": guestData?.modelId,
          "customerId": response.data?.data?.id,
          "cardNumber": data.cardNumber,
          "cardCvv": data.cvv,
          "cardExpiryMonth": data?.expireDate?.split('/')[0],
          "cardExpiryYear": data?.expireDate?.split('/')[1]
        }
        guestData.customerId = reqData.customerId;

        localStorage.setItem('guestData', JSON.stringify(guestData));
        createCustomerCard(reqData);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        setErrorMessage(error.response?.data.message);
      });
  }

  const createCustomerCard = (data: any) => {
    axios.post('https://haspro-dev.azurewebsites.net/api/CustomerCard', data)
      .then(function (response) {
        const proceedData = {
          "customerId": data?.customerId,
          "itemPriceId": monthlyPlan?.id
        }
        Procced(proceedData);
      })
      .catch(function (error) {
        setLoadingStatus(false);
        if (error.response?.data.message.includes('card number')) {
          setErrorMessage('Invalid card number.');
        } else if (error.response?.data.message.includes('Expired')) {
          setErrorMessage('Expired Card.');
        } else {
          setErrorMessage(error.response?.data.message);
        }
      });
  }

  const Procced = (data: any) => {
    axios.post('https://haspro-dev.azurewebsites.net/api/SubscriptionChargeBee/Procced', data)
      .then(function (response) {
        setLoadingStatus(false);
        navigate("/checkout-success");
      })
      .catch(function (error) {
        setLoadingStatus(false);
        setErrorMessage(error.response?.data.message || error.response?.data.title);
      });
  }

  return (
    <>
      {loadingStatus ? <Loader /> : ''}
      <s.Checkout>
        <Container width="533px">
          <s.CenteredTitle>Add your billing info</s.CenteredTitle>
          <s.CenteredDescription>Confirm your selections and add a payment method to your account</s.CenteredDescription>
          <Card>
            <s.CheckoutSubtitle>Your Information</s.CheckoutSubtitle>
            <s.InformationList>
              <s.InformationListItem>
                <s.Text color="light">Full name:</s.Text>
                <s.Text>{guestData?.firstName + ' ' + guestData?.lastName}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Vehicle:</s.Text>
                <s.Text>{guestData?.vehicleYear + (guestData?.brandName ? (' ' + guestData?.brandName + ' ' + guestData?.modelName) : '')}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Mileage:</s.Text>
                <s.Text>{guestData?.vehicleMileage + ' miles'}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Phone number:</s.Text>
                <s.Text>{guestData?.phone}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Email:</s.Text>
                <s.Text>{guestData?.email}</s.Text>
              </s.InformationListItem>

              <s.InformationListItem>
                <s.Text color="light">Zip code:</s.Text>
                <s.Text>{guestData?.zipCode}</s.Text>
              </s.InformationListItem>
            </s.InformationList>
          </Card>
          <Card>
            <s.CheckoutSubtitle>Your Subscription</s.CheckoutSubtitle>
            <s.PlanWrapper>
              <div>
                <s.Text>{monthlyPlan?.name}</s.Text> <br />
                <s.Text color="light">Free trial ends 2/20/22</s.Text>
              </div>
              <div>
                <s.Text>
                  <strong>${(+monthlyPlan?.price) / 100}/mo</strong>
                </s.Text>
              </div>
            </s.PlanWrapper>
            {/* <s.ChangePlan color="light">Change plan</s.ChangePlan> */}
          </Card>

          <Card>
            <s.PlanWrapper>
              <s.CheckoutSubtitle>Total Due Today</s.CheckoutSubtitle>
              <s.TotalDueAmount>$0.00</s.TotalDueAmount>
            </s.PlanWrapper>
          </Card>

          <Card>
            {/* <s.PaymentTypeWrapper>
            <s.PaymentType onClick={() => setActivePaymentType(0)} className={activePaymentType === 0 ? "active" : ''}>
              <img src={creditCard} />
              <span>Credit card</span>
            </s.PaymentType>
            <s.PaymentType onClick={() => setActivePaymentType(1)} className={activePaymentType === 1 ? "active" : ''}>
              <img src={paypal} />
              <span>Paypal</span>
            </s.PaymentType>
          </s.PaymentTypeWrapper> */}


            <s.CheckoutForm onSubmit={handleSubmit(onSubmit)}>
              <s.CardNumberLabel>Card Number
                <img src={info} />
              </s.CardNumberLabel>
              <FormControl>
                <s.IconInputWrapper>
                  <Input label="0000-0000-0000-0000" mask="cardNumber" name="cardNumber" register={register} type="number" validate={{
                    required: 'Card Number is required',
                  }} />
                  <s.InputIcon>
                    <img src={cards} width="91" />
                  </s.InputIcon>
                </s.IconInputWrapper>
                {!cardNumberValidation && (<FormError>Min. length is 16</FormError>)}
                {errors.cardNumber && (<FormError>{errors.cardNumber.message}</FormError>)}
              </FormControl>

              <s.ThreeInputGroup>
                <FormControl>
                  <s.IconInputWrapper>
                    <Input label="CVV" name={'cvv'} mask={'cvv'} register={register} type="text" validate={{ required: 'CVV is required' }} />
                    <s.InputIcon>
                      <img src={info} />
                    </s.InputIcon>
                  </s.IconInputWrapper>
                  {!cvvValidation && (<FormError>Enter valid CVV</FormError>)}
                  {errors.cvv && (<FormError>{errors.cvv.message}</FormError>)}
                </FormControl>

                <FormControl>
                  <Input label="MM/YY" mask="mmyy" name={'expireDate'} register={register} type="text" validate={{ required: 'Expire Date is required' }} />
                  {!expireDateValidation && (<FormError>Enter valid date</FormError>)}
                  
                  {(!monthValidation && expireDateValidation) && (<FormError>Month should not be more than 12</FormError>)}
                  {errors.expireDate && (<FormError>{errors.expireDate.message}</FormError>)}
                </FormControl>

                <FormControl>
                  <Input label="Zip Code" mask="zip" name={'zipCode'} register={register} type="text" />
                </FormControl>
              </s.ThreeInputGroup>
              <Button variant="primary" type="submit">Continue</Button>
              <FormError>{errorMessage} </FormError>
            </s.CheckoutForm>
          </Card>

          <Card>
            <s.PlanWrapper>
              <s.Text>
                <strong>After any free trial(s) and/or discount(s), you will be charged ${(+monthlyPlan?.price) / 100}/mo on a recurring basis.</strong>
              </s.Text>
              <s.LightText>
                If you do not cancel a service during its free trial period, you will be charged. Your subscription will continue until you cancel. To cancel, visit our support page <s.Link>Here.</s.Link> By selecting ‘CONTINUE’, you agree to above terms.
              </s.LightText>
            </s.PlanWrapper>
          </Card>
        </Container>
        <s.Separator></s.Separator>
      </s.Checkout>
    </>
  )
};


export default Checkout;