import * as s from './testimonial.styled';
import Container from 'components/container';
import testimonial from 'assets/images/testimonial.png';

const Testimonial: React.FC = () => (
  <s.Testimonials>
    <Container width="505px">
    <s.PreTitle>WHAT OUR CUSTOMERS SAY</s.PreTitle>
    <s.Title>This is way too inexpensive to pass it up! For $9 it is not worth not having this!</s.Title>
    </Container>
    <s.Testimonial>
      <img src={testimonial} alt="Dave"/>
      <div>
        <h4>Dave</h4>
        <p>2012 Chevrolet Cobalt</p>
      </div>
    </s.Testimonial>
  </s.Testimonials>
)

export default Testimonial;