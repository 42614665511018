import styled from "styled-components";

export const HeaderWrap = styled.div`
    display: block;
`;

export const Header = styled.header`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 14px ${({theme}) => theme.grid?.gridGutter} 8px;
    border-bottom: 2px solid ${({theme}) => theme.colors?.grey};
    
    @media ${({theme})=> theme.media?.extraSmall} {
        padding: 44px 0 14px;
    }
    @media ${({theme})=> theme.media?.medium} {
        padding: 14px ${({theme}) => theme.grid?.gridGutter} 8px;
    }

    img {
        max-width: 90px;
        margin-right: auto;
        margin-left: 0;
        display: block;
    }
`;
