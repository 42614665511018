import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors?.greyBg};
  margin-bottom: 12px;
  @media ${({ theme }) => theme.media?.extraSmall} { 
    padding: 16px;
  }
  @media ${({ theme }) => theme.media?.medium} { 
    padding: 24px;
  }
`;
